import React from 'react';
import styled from '@emotion/styled';
import { Link, useStaticQuery, graphql } from 'gatsby';

import mediaqueries from '@narative/gatsby-theme-novela/src/styles/media';

const ArticlesNavigation: React.FC<{}> = () => {

    const data = useStaticQuery(graphql`
        {
            allContentfulDivision(filter: {depth: {eq: 1}}) {
                edges {
                    node {
                        name
                        slug
                        depth
                    }
                }
            }
        }
    `);

    const divData = data.allContentfulDivision.edges;

    if (!divData) {
        return null;
    }

    function renderItems(divData: any) {
        return (
            <>
                {divData.map((divisionOne: any) => {
                    return (
                        <NavigationItem key={divisionOne.node.slug}>
                            <NavigationLink to={`/${divisionOne.node.slug}/`}>
                                {divisionOne.node.name}
                            </NavigationLink>
                        </NavigationItem>
                    );
                })}
            </>
        )
    }

    return (
        <NavigationList>
            {renderItems(divData)}
        </NavigationList>
    );
};

export default ArticlesNavigation;

const NavigationList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
`;

const NavigationDropdownContent = styled.div`
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-color: ${p => p.theme.colors.background};
    z-index: 2;
`;

const NavigationItem = styled.li`
    display: inline-block;
    float: left;
    border-right: 1px solid ${p => p.theme.colors.horizontalRule};

    &:hover ${NavigationDropdownContent} {
        display: block;
    };

    ${mediaqueries.tablet`
        float: none;
    `};

    ${mediaqueries.phablet`
        float: none;
    `};

    &:last-child {
        border-right: 0;
    };
`;

const NavigationLink = styled(Link)`
    display: inline-block;
    text-align: center;
    padding: 14px 16px;
    color: ${p => p.theme.colors.grey};
    text-decoration: none;

    &:hover {
        color: ${p => p.theme.colors.background};
        background-color: ${p => p.theme.colors.grey};
    };
`;

const NavigationDropdownLink = styled(Link)`
    color: ${p => p.theme.colors.grey};
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;

    &:hover, &:hover ${NavigationLink} {
        background-color: ${p => p.theme.colors.grey};
        color: ${p => p.theme.colors.background};
    };
`;

